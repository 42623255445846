<template>
  <v-container fluid>
    <v-card-title>추천 검색어</v-card-title>
    <v-card-subtitle class="py-0">
      <v-divider class="my-3" style="border-color: black" />
    </v-card-subtitle>
    <v-row class="pa-4" no-gutters>
      <span class="pa-2 pr-6">
        추천 문구
      </span>
      <v-text-field
        v-model="req.title"
        style="max-width: 600px"
        color="#9146ff"
        outlined
        hide-details
        dense
        placeholder="추천 문구로 노출될 문구를 입력해주세요."
        :rules="[(v) => !!v]"
      />
      <span class="pa-2 ml-8">
        노출여부
      </span>
      <v-btn-toggle v-model="req.isShow" mandatory color="#9146ff" dense>
        <v-btn class="pa-5" :value="true">
          노출
        </v-btn>
        <v-btn class="pa-5" :value="false">
          비노출
        </v-btn>
      </v-btn-toggle>
      <v-btn :color="req.id ? 'green' : '#ffd600'" :dark="req.id ? true : false" light depressed class="pa-5 ml-12" @click="createRecommendSearch">
        {{ req.id ? '수정' : '추가' }}
      </v-btn>
      <v-btn v-if="req.id" dark color="red" depressed class="pa-5 ml-3" @click="req = {}">
        취소
      </v-btn>
    </v-row>
    <v-row class="pa-4" no-gutters>
      <span class="pa-2">
        추천 검색어
      </span>
      <v-combobox
        v-model="req.wordList"
        style="max-width: 600px"
        color="#9146ff"
        multiple
        outlined
        hide-details
        dense
        :append-icon="null"
        placeholder="추천 검색어를 입력해주세요. (엔터로 구분 가능합니다)"
        :rules="[(v) => !!v[0]]"
      >
        <template v-slot:selection="{ item, parent }">
          <v-chip label small>
            <span class="pr-2">
              {{ item }}
            </span>
            <v-icon small @click="parent.selectItem(item)">
              $delete
            </v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </v-row>
    <v-card-subtitle class="py-0">
      <v-divider class="my-3" style="border-color: black" />
    </v-card-subtitle>
    <v-card outlined flat>
      <v-data-table :headers="headers" :items="recommendSearchList">
        <template v-slot:item.updatedAt="{ item }">
          {{ day(item.updatedAt) }}
        </template>
        <template v-slot:item.isShow="{ item }">
          {{ item.isShow ? '노출' : '비노출' }}
        </template>
        <template v-slot:item.wordList="{ item }">
          <v-chip v-for="name in item.wordList" :key="name" label color="#888" text-color="#FFF" class="mr-1">
            {{ name }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn depressed color="green" dark class="mr-5" @click="update(item)">
            수정
          </v-btn>
          <v-btn depressed color="red" dark @click="deleteRecommendSearch(item.id)">
            삭제
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import { day } from '@/helper'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      headers: [
        { text: 'no', value: 'no' },
        { text: '노출상태', value: 'isShow' },
        { text: '추천 문구', value: 'title' },
        { text: '추천 검색어', value: 'wordList' },
        { text: '최종 등록일시', value: 'updatedAt' },
        { text: null, value: 'action' },
      ],
      recommendSearchList: [],
      edit: null,
      req: {
        id: null,
        title: '',
        wordList: [],
        isShow: true,
      },
    })

    const getListRecommendSearch = async () => {
      state.recommendSearchList = await root.$store.dispatch('recommendSearch/getListRecommendSearch')

      state.recommendSearchList = state.recommendSearchList.map((x, i) => ({
        ...x,
        no: i + 1,
        wordList: x.RecommendSearchWord.map((y) => y.text),
      }))
    }

    const update = async (item) => {
      state.req = Object.assign({}, item)
    }

    const updateRecommendQuestion = async () => {
      if (!state.req.title) return alert('추천 문구는 필수입니다.')
      else if (!state.req.wordList[0]) return alert('추천 검색어는 필수입니다.')

      if (!confirm('수정하시겠습니까?')) return

      try {
        await root.$store.dispatch('recommendSearch/updateRecommendSearch', {
          id: Number(state.req.id),
          title: state.req.title,
          wordList: state.req.wordList,
          isShow: state.req.isShow,
        })

        state.req = {
          id: null,
          title: '',
          wordList: [],
          isShow: true,
        }
        getListRecommendSearch()
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    const createRecommendSearch = async () => {
      if (!state.req.title) return alert('추천 문구는 필수입니다.')
      else if (!state.req.wordList[0]) return alert('추천 검색어는 필수입니다.')

      if (state.req.id) return updateRecommendQuestion()
      state.req.id = undefined
      if (!confirm('추가하시겠습니까?')) return

      try {
        await root.$store.dispatch('recommendSearch/createRecommendSearch', state.req)
        state.req = {
          id: null,
          title: '',
          wordList: [],
          isShow: true,
        }
        getListRecommendSearch()
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    const deleteRecommendSearch = async (id) => {
      if (!confirm('삭제하시겠습니까?')) return

      try {
        await root.$store.dispatch('recommendSearch/deleteRecommendSearch', {
          id: Number(id),
        })
        getListRecommendSearch()
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    onBeforeMount(() => {
      getListRecommendSearch()
    })

    return {
      ...toRefs(state),
      getListRecommendSearch,
      update,
      createRecommendSearch,
      deleteRecommendSearch,
      day,
    }
  },
})
</script>
